import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/browser'
import { FeatureFlagsProvider } from '@fs/zion-flags'
import { allLocales, setAppLocales } from '@fs/zion-locale'
import { targetEnv, sentryDSN, mergeSentryConfig } from '@fs/zion-config'
import Root from '@fs/zion-root'
import zionDebug from '@fs/zion-debug'
import { AppLayout } from '@fs/zion-ui'
import { Router } from '@fs/zion-router'

import { BrightspotProvider } from '@fs/zion-cms'
import { NoticeLoading } from '@fs/zion-icon'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './locales'

const debug = zionDebug('zion-cms')

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      dsn: sentryDSN,
    })
  )
}

setAppLocales(allLocales)

// Get the first element of the path. This should be the site
const basePath = window?.location?.pathname?.split('/')?.[2] || ''
const baseSites = ['zion-cms', 'zion-cms-qa', 'centers', 'dna-testing', 'fhsites', 'innovate']
const baseSite = baseSites.find((entry) => entry === basePath) || basePath
const sites =
  targetEnv !== 'prod'
    ? [
        'blog',
        'centers',
        'consultant',
        'dna-testing',
        'innovate',
        'library',
        'fhsites-beta',
        'mobile-apps',
        'newsroom',
        'zion-cms-beta',
        'zion-cms-qa-beta',
      ]
    : ['blog', 'centers', 'consultant', 'dna-testing', 'innovate', 'library', 'mobile-apps', 'newsroom']
const subsites = ['centers', 'library', 'fhsites-beta', 'innovate', 'zion-cms-beta', 'zion-cms-qa-beta']
let site = baseSite
if (targetEnv !== 'prod' && sites.includes(`${site}-beta`)) {
  site = `${site}-beta`
}

if (basePath === 'family-history-library') {
  site = 'library'
}

debug(`index basePath=${basePath}, baseSites=${baseSites}, baseSite=${baseSite}`)
debug(`index sites=${sites}`)

const header = { hidden: site === 'cfprgm' }
const footer = { hidden: site === 'cfprgm' }

const FrontierRoot = () => (
  <React.StrictMode>
    <Suspense fallback={<NoticeLoading />}>
      <FeatureFlagsProvider mockFlags={mockFlags}>
        <BrightspotProvider sites={sites} subsites={subsites} hasCustomContent>
          <Router basename="/">
            <Root analytics header={header} footer={footer}>
              <AppLayout fullWidth>
                <App site={site} />
              </AppLayout>
            </Root>
          </Router>
        </BrightspotProvider>
      </FeatureFlagsProvider>
    </Suspense>
  </React.StrictMode>
)

ReactDOM.render(<FrontierRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
